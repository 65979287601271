<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="3"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  ASC
                </option>
                <option :value="true">
                  DESC
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(status)="data">
          <span class="text-capitalize">{{ data.item.status }}</span>
        </template>
        <template #cell(member_name)="data">
          <span class="text-center">{{ data.item.member.member_first_name }}  {{ data.item.member.member_last_name }}</span>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BButton, BFormGroup, BFormCheckbox,
    BCard, BCardBody,
    BTable, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BImg,
		BDropdown, BDropdownItem, BOverlay, BModal, BFormCheckboxGroup
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
import { map } from 'postcss-rtl/lib/affected-props'

  export default {
    components: {
      BRow, BCol, BCard, BCardBody, BButton, BFormGroup, BFormCheckbox,
      BTable, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BImg,
      BDropdown, BDropdownItem, BOverlay, BModal, BFormCheckboxGroup
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        banner_status: false,
        active_role: null,
        perPage: 50,
        pageOptions: [10, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        modalConfig: false,
        modalConfigId: null,
        configSelected: [],
        datas: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'member_name', label: 'Member', sortable: true },
          { key: 'corporate.corporate_name', label: 'Corporate', sortable: true },
          { key: 'value', label: 'Answer' },
        ],
        active_role: null,
        corporate_id: null
      }
    },
		computed: {
			sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
      items() {
        if (this.active_role === 'superadmin') {
          return this.datas
        } else {
          return this.datas
        }
      }
		},
		created() {
      this.checkAuth()
    },
		mounted() {
      this.corporate_id = localStorage.getItem('corporate_id')
	    this.active_role = localStorage.getItem('role')
      this.getSurvey()
		}, 
    methods: {
      getSurvey() {
        this.$store.dispatch('survey/loadDetailsSurvey', this.$route.params.id).then((res) => {
          if (res) {
            this.datas = res.data.data
            console.log(this.datas.filter(obj => obj.corporate_id === this.corporate_id))
          }
        });
      }
    }
  }
</script>